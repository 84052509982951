
export default {
  name: 'PagesAffiliateGetStartedOnboardingStep',
  props: {
    hasPartner: {
      type: Boolean,
      default: false,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIds: [],
    };
  },
  computed: {
    step() {
      return this.$route.params.step;
    },
    i18nBasePath() {
      return `pages.preSignupOnboarding.${this.step}`;
    },
    pageContent() {
      return this.$t(`${this.i18nBasePath}`);
    },
    options() {
      return this.pageContent.items.filter((item) => {
        if (item.showIf) {
          return item.showIf.some((condition) => this[condition]);
        }
        if (item.showIfNot) {
          return !item.showIfNot.some((condition) => this[condition]);
        }
        return true;
      });
    },
    selectedIdsModel: {
      get() {
        return this.selectedIds;
      },
      set(newIds) {
        const oldItems = this.options.filter((item) =>
          this.selectedIds.includes(item.id)
        );
        const newItem = this.options.find(
          (item) =>
            newIds.includes(item.id) && !this.selectedIds.includes(item.id)
        );
        const unselectedItem = oldItems.find(
          (item) => !newIds.includes(item.id)
        );

        const hasClearAnotherItemsInOldItems = oldItems.some(
          (item) => item.clearAnotherItems
        );
        if (newItem?.clearAnotherItems || hasClearAnotherItemsInOldItems) {
          this.selectedIds = newItem ? [newItem.id] : [];
        } else {
          this.selectedIds = newIds;
        }

        this.$nuxt.$emit('sendTrackingEvent', {
          event: 'click',
          props: {
            element_id: `pre-signup-onboarding__${this.step}__item__${newItem?.id || unselectedItem?.id}--${
              newItem ? 'selected' : 'unselected'
            }`,
            page_path: this.$route.path,
          },
        });
      },
    },
  },
  methods: {
    next() {
      if (this.step === 'family') {
        const selectedOptions = this.options.filter((item) =>
          this.selectedIds.includes(item.id)
        );
        const hasPartner = selectedOptions.some(
          (item) => item.relatedData === 'hasPartner'
        );
        const hasChildren = selectedOptions.some(
          (item) => item.relatedData === 'hasChildren'
        );
        this.$emit('update:hasPartner', hasPartner);
        this.$emit('update:hasChildren', hasChildren);
      }

      this.$router.push(this.pageContent.nextStepUrl);
    },
  },
};
