
export default {
  name: 'PagesAffiliateGetStartedOnboarding',
  middleware({ app, route, redirect }) {
    if (!app.i18n.te(`pages.preSignupOnboarding.${route.params.step}`)) {
      redirect('/get-started');
    }
  },
  data() {
    return {
      hasPartner: null,
      hasChildren: null,
    };
  },
  mounted() {
    if (!this.hasPartner || !this.hasChildren) {
      this.$router.push('/get-started/onboarding/family');
    }
  },
};
