import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=636d2800"
import script from "./Default.vue?vue&type=script&lang=js"
export * from "./Default.vue?vue&type=script&lang=js"
import style0 from "./Default.vue?vue&type=style&index=0&id=636d2800&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLogo: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Logo.vue').default,AppAffiliateLogo: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/AffiliateLogo.vue').default,AppDecoratedText: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/DecoratedText.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppIcon: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Icon.vue').default,AppBadge: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Badge.vue').default,WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default})
